import React from 'react';
import { Link } from 'react-router-dom';

import logoClarkeEnergiaDoubleLine from '@assets/logo-clarke-energia.svg';

import ButtonComponent from '@components/atoms/button/button';

const AuthErrorPage: React.FC = () => {
  const linkAuthError = 'https://clarke-production.us.auth0.com/oidc/logout?federated';
  return (
    <div className="flex overflow-y-auto flex-col justify-center items-center h-screen align-middle md:flex-row md:px-36 ">
    <img src={logoClarkeEnergiaDoubleLine} alt="Logotipo da Clarke Energia" width={280} height={160} />
      <div className="flex flex-col justify-start items-start pl-10 mx-20 text-left align-middle border-l-2 border-clarke-green">
        <div className="font-semibold text-heading-xxLarge">Erro de Autenticação</div>
        <div className="mt-2 w-full text-base font-light text-neutral-60 text-paragraph-large">
            Não foi possível completar o login. Tente novamente.
        </div>
        <div>
        <Link to={linkAuthError}>
            <ButtonComponent
              kind="secondary"
              size="2"
              label="Tentar novamente"
              className="text-paragraph-small px-4 py-2 mt-4 w-40 h-10"
            />
        </Link>
        </div>
      </div>
    </div>
  );
};

export default AuthErrorPage;