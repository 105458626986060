import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import Layout from '@components/layout/main-layout';
import HomePage from '@pages/home-page';
import AboutUsPage from '@pages/about-us-page';
import OurSolutionsPage from '@pages/our-solutions-page';
import AuthErrorPage from '@pages/AuthErrorPage';

import {
  HOME_PATH,
  ABOUT_US_PATH,
  OUR_SOLUTIONS_PATH,
  OLD_FREE_ENERGY_MARKETPLACE_PATH,
  NEW_FREE_ENERGY_MARKETPLACE_PATH,
} from './constants';

const InstitutionalSiteRouter: React.FC = () => {
  return (
    <Routes>
      <Route path={HOME_PATH} element={<Layout />}>
        <Route index element={<HomePage />} />
        <Route path={ABOUT_US_PATH} element={<AboutUsPage />} />
        <Route path={OUR_SOLUTIONS_PATH} element={<OurSolutionsPage />} />
        <Route path={OLD_FREE_ENERGY_MARKETPLACE_PATH} element={<Navigate to={NEW_FREE_ENERGY_MARKETPLACE_PATH} />} />

        <Route path="*" element={<Navigate to={HOME_PATH} replace />} />
      </Route>
      <Route path="/auth-error" element={<AuthErrorPage />} />
    </Routes>
  );
};

export default InstitutionalSiteRouter;
